.resume_section {
    min-height: 100vh;
    border: 1px solid var(--color-primary-font);
    border-radius: 12px;
    color: var(--color-primary-font);
    margin: 3rem auto;
}

.resume_section h1 {
    margin-bottom: 1rem;
    text-align: center
}

/* ================ Contact Section ==============*/
.resume_contact_container {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.resume_contact_container span {
    color: var(--color-primary-font);

}


.resume_icons {
    color: var(--color-primary-font);
    margin: -0.25rem .25rem;
    padding: 0 0;
}

.linkedIn,
.website,
.resume_emai,
.resume_github {
    text-decoration: underline;
}

/* ================ Contact Section ==============*/



/* ================ Objective Section ==============*/
.objective_container {
    margin-left: 5rem;
    margin-right: 5rem;
}

.objective_container p {
    text-align: justify;
}


/* ================ Objective Section ==============*/



/* ================ Skills Section ==============*/
.resume_skills {
    margin: 1rem 0 0 2rem;
}



.resume_list_style {
    align-items: center;
    display: flex;
    margin-top: -.25rem;
    padding: 0;
    height: 2rem;
}

.circle {
    border-radius: 50%;
    background-color: var(--color-primary-font);
    position: relative;
    margin-left: 2rem;
    height: .5rem;
    width: .5rem;
}

.skill_list {
    display: flex;
    gap: .40rem;
    margin: 0 0 0 2rem;
}


.resume_skill {
    font-size: 1rem;
    font-weight: 300;
}

/* ================ Skills Section ==============*/

/* ================ Project Section ==============*/
.resume_projects {
    margin: 2rem 0 .5rem 2rem;
}


.project_list_style {
    align-items: center;
    display: flex;

}

.project_list_style h4 {
    margin-left: 2rem;
    color: var(--color-primary-font);

}


.project_list_style h4:hover {

    color: var(--color-bg-bright);
}

.projectGit {

    color: var(--color-primary-font);
    /* border: 2px solid white; */
}

.project_list_style span {
    font-size: .93rem;
    font-weight: 200;
    margin-left: .5rem;

}

/* ================ Project Section ==============*/

/* ================ Experience Section ==============*/

.resume_experience {
    margin: 2rem 0 .5rem 2rem;
}


/* .resume_experience_section span {
    border-bottom: 1px solid var(--color-primary-font);
    position: relative;
    width: 88%;
    margin-left: 1%;
} */

.top_line_experience {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem 0 2rem;


}

.top_line_experience:nth-child(6) {
    margin-top: .75rem;
}

.top_line_experience .experience_title {
    width: 40%;
}

.work_dates h4 {
    margin: 0 2rem 0 2rem;
}

.experience_place {
    text-decoration: underline;
}

.experience_body_section {
    align-items: baseline;
    display: flex;
}

.experience_body_section li {
    font-size: .93rem;
    font-weight: 200;
    margin: .5rem 2rem 0 2rem
}

.trainer {
    margin-top: 1rem;
}

.liability_examiner {
    font-size: .90rem;
    font-style: italic;
    font-weight: 500;
    margin: .75rem 0 -.45rem 2rem;
}

/* ================ Experience Section ==============*/

/* ================ Education Section ==============*/

.resume_education_section {
    margin-bottom: 2rem;
}

.education_experience {
    margin: 1rem 0 .5rem 2rem;
}

/* .education_experience span {
    border-bottom: 1px solid var(--color-primary-font);
    position: relative;
    width: 89%;
    margin-left: 1%;
} */

.education_top_line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 0 2rem -.55rem 2rem; */
    margin: 0 0 0 2rem;


}

.education_body {
    margin-bottom: 1rem;
}

.education_place {
    margin-bottom: 1rem;
    text-align: left;
}


/* ================ Education Section ==============*/

.download_resume {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5rem;

}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    .resume_contact_container {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        justify-content: space-between;
        margin: 0 0 0 2rem;
    }

    .skill_list {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(3, 50%);
        gap: 1.5rem;
        row-gap: 0;
        text-align: left;
        margin: 0 0 1rem 2rem;
    }

    .resume_list_style .circle {
        display: none;
    }

    .project_list_style {
        width: 95%;
    }


    .top_line_experience {
        flex-direction: column;
        gap: .5rem;
    }

    .resume_icons {
        font-size: .9rem;
    }

    .linkedIn,
    .website,
    .resume_emai,
    .resume_github {
        font-size: .9rem;
    }

}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .linkedIn,
    .website,
    .resume_emai,
    .resume_github {
        font-size: .85rem;
    }

    .resume_contact_container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 2rem 1rem;
    }


    /* ================ Objective Section ==============*/
    .resume_skills,
    .resume_projects,
    .resume_experience,
    .education_experience {
        margin: 1rem 0 0 1rem;
    }

    .resume_skills span,
    .resume_projects span,
    .resume_experience span,
    .education_experience span {
        width: 95%;
    }

    .objective_container {
        width: 92%;
        margin: .5rem 0 2rem 1rem;
    }

    /* ================ Objective Section ==============*/


    /* ================ Skills Section ==============*/


    .skill_list {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(3, 30%);
        gap: 10%;
        row-gap: 0;
        text-align: left;
        margin: 0 0 1rem 1rem;
    }

    .resume_list_style .circle {
        display: none;
    }

    /* ================ Skills Section ==============*/


    /* ================ Project Section ============== */

    .project_list_style {
        margin: 1rem 0 0 -1rem;
        width: 100%;

    }



    .project_list_style .circle {
        display: none;
    }

    /* ================ Project Section ============== */



    /* ================ Experience Section ============== */
    .top_line_experience {
        margin-left: 1rem;
    }

    .work_dates h4 {
        margin: 0 2rem 0 1rem;
    }

    .experience_body li {
        list-style-type: disc;
    }

    .experience_body_section .circle {
        display: none;

    }

    .experience_body_section {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    /* ================ Experience Section ============== */


    /* ================ Education Section ==============*/


    .education_top_line {
        flex-direction: column;
        align-items: center;

    }

    .education_place_top {
        margin-left: 0;
    }

    .education_body li {
        text-align: center;

    }

    /* ================ Education Section ==============*/

    .button_light.resume,
    .button_dark.resume {
        border-radius: 6px;
        font-size: .9rem;
        width: 9rem;

    }

    /* 

    .resume_list_style {
        justify-content: center;
    }

    .resume_list_style .circle {
        display: none;
    }

      .resume_list_style .circle {
        display: none;
    }

  

    

    .top_line_experience {
        flex-direction: column;
        gap: .5rem;
    }

    .work_dates {
        margin-top: .5rem;
    } */

}