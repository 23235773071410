.footer_section {
    background-color: var(--color-bg-bright);
    width: 100%;
}

.footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 2rem 0;

}

.footer_links {
    display: flex;
    gap: 5rem;
    margin: 2rem 0 2rem 0;

}

.footer_link a {
    color: var(--color-font-black-grey);
    transition: var(--transition);
}

.footer_link a:hover,
.footer_social a:hover {
    color: var(--color-font-hover);
}

.footer_social {
    display: flex;
    gap: 2rem;
}

.footer_social a {
    color: var(--color-font-black-grey);
    font-size: 1.35rem;
}

.footer_copyright {
    margin: 2rem 0 0 0;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .footer_container {
        display: flex;

    }

    .footer_links {
        flex-direction: column;
        gap: 0rem;
        margin: 0 0 0 0;
        text-align: center;
    }

    .footer_link {
        margin-bottom: -.5rem;
    }

    .footer_social {
        margin: 2rem 0 0 0;

    }


}