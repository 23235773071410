@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    -webkit-box-sizing: border-box;
}

:root {
    --color-bg-primary: #000000;
    --color-bg-variant: #1a1919;
    --color-bg-bright: #7558f6;


    --color-primary-font: #eaeaea;
    --color-font-black-grey: #262525;
    --color-font-hover: #a3a3a3;

    --color-light-button-outline: #676666;

    --transition: all 300ms ease-in;

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    background-color: var(--color-bg-primary);
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Poppins', sans-serif;
    background-image: url('../src/assets/bgBlack1.jpeg');
    background-size: cover;
    background-blend-mode: multiply;

}

/* ==================== GENERAL STYLES ==================== */
a {
    color: var(--color-bg-bright);
    text-decoration: none;
    transition: var(--transition);
}

a:hover,
a.active {
    color: var(--color-font-hover);
}


ul {
    list-style: none;
}


.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 400
}

h1 {
    font-size: 2.5rem;
}


ul>li {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 1rem 0;
}


/* 
section>h2,
section>h5 {
  text-align: center;

}

section>h2 {
  color: var(--color-bg-bright);
  font-size: 3rem;
}

section>h5 {
  color: var(--color-light-button-outline);
  font-size: 1.25rem;
} */


img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.nav_button {
    background-color: transparent;
    border: 1px solid var(--color-bg-bright);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    font-size: 1.1rem;
    margin-top: 1.25rem;
    padding: .25rem 1rem;
    transition: var(--transition)
}

.nav_button:hover {
    background-color: var(--color-bg-bright);
    border: 2px solid var(--color-bg-bright);
    box-shadow: 0 0 35px var(--color-bg-bright);
    color: var(--color-bg-primary);
    /* letter-spacing: 0.09rem; */

}

.button_light {
    background-color: transparent;
    border: 1px solid var(--color-bg-bright);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    color: var(--color-bg-bright);
    font-size: 1.1rem;
    margin-top: 1.25rem;
    outline: none;
    padding: .35rem 1.25rem;
    transition: var(--transition)
}



.button_dark {
    background-color: var(--color-bg-bright);
    border: 1px solid var(--color-bg-bright);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    color: var(--color-bg-primary);
    border-radius: 12px;
    font-size: 1.1rem;
    margin-top: 1.25rem;
    outline: none;
    padding: .35rem 1.25rem;
    transition: var(--transition)
}

.button_dark:hover,
.button_light:hover {
    border: 2px solid var(--color-bg-bright);
    background-color: var(--color-primary-font);
    box-shadow: 0 0 2.19rem var(--color-bg-bright);
    color: var(--color-bg-bright);
    /* letter-spacing: 0.03rem; */
}

.small_button_light {
    border: 1px solid var(--color-bg-primary);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    background-color: transparent;
    border-radius: 12px;
    color: var(--color-primary-font);
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1.25rem;
    outline: none;
    padding: .20rem 1.25rem;
    transition: var(--transition)
}


.small_button_dark {
    background-color: var(--color-bg-primary);
    border: 1px solid var(--color-bg-bright);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    color: var(--color-bg-bright);
    border-radius: 12px;
    box-shadow: -.15rem .15rem .25rem var(--color-bg-primary);
    font-size: 1rem;
    margin-top: 1.25rem;
    outline: none;
    padding: .20rem 1.25rem;
    transition: var(--transition)
}

.small_button_dark:hover {
    border: 2px solid var(--color-bg-bright);
    background-color: var(--color-primary-font);
    box-shadow: 0 0 2.19rem var(--color-bg-bright);
    color: var(--color-bg-bright);
    /* letter-spacing: 0.03rem; */
}


@keyframes showRight {
    100% {
        width: 0;
    }
}

@-webkit-keyframes showRight {
    100% {
        width: 0;
    }
}

@font-face {
    font-family: "Ozone";
    src: local("OzoneMedium"), url("./fonts/Ozone-xRRO.ttf") format("truetype");
}

@font-face {
    font-family: "HighSpeed";
    src: local("HighSpeedMedium"), url("./fonts/HighSpeed.ttf") format("truetype");
}

@font-face {
    font-family: "MusicNet";
    src: local("MusicNetMedium"), url("./fonts/MUSICNET.ttf") format("truetype");
}

@font-face {
    font-family: "ManyDots";
    src: local("ManyDotstMedium"), url("./fonts/ManyDotsDemoRegular.ttf") format("truetype");
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }


    .button_light,
    .button_dark {
        border-radius: 6px;
        font-size: .9rem;
        line-height: 1;
        text-align: center;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section {
        margin-top: 5rem;
    }

    .button_light,
    .button_dark {
        border-radius: 6px;
        font-size: .9rem;

    }

}