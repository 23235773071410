.projectDetailsSection {
    align-items: center;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin-bottom: 5rem;
}

.projectDetailsSectionTitle {
    margin-top: 3rem;
}

.projectDetailsSectionTitle h2 {
    color: var(--color-primary-font);
    font-size: 3rem;
}


.projectDetailsContainer {
    margin-top: 5rem;
}


.projectDetailsImgContainer img {
    border-radius: 20px;
}

.projectDetailsTitleContainer {
    margin-top: 1rem;
}

.projectDetailsTitleContainer h3 {
    color: var(--color-primary-font);
    font-size: 2.5rem;
    margin-bottom: -.5rem;

}

.projectDetailsTitleContainer h4 {
    color: var(--color-primary-font);
    font-size: 1.5rem;
    font-weight: 200;
}

.projectDetailsTechStackContainer {
    display: flex;
    gap: 1rem;
    margin: .5rem 0 .50rem 0;
}


.projectDetailsTechStackContainer div {
    color: var(--color-primary-font);
    font-size: 1.75rem;
}


.projectDetailsDemoSignInContainer {
    margin-bottom: 1rem;

}

.projectDetailsDemoSignInContainer h3 {
    color: var(--color-primary-font);
    font-size: 1.25rem;
}

/* .projectDetailsDetailContainer p {
    color: var(--color-primary-font);
    white-space: pre-wrap;
} */

.projectDetailsDetailContainer p {
    color: var(--color-primary-font);
    margin-bottom: .5rem;
}

.projectDetailspainPointContainer {
    margin-bottom: 1rem;
}

.projectDetailspainPointContainer h3 {
    color: var(--color-primary-font);
    font-weight: 500;
    font-size: 1.25rem;

}

.projectDetailspainPointContainer p {
    color: var(--color-primary-font);
    /* white-space: pre-wrap; */
    margin-bottom: .5rem;

}

.projectDetailsViewContainer {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: -1rem;


}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}



/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .projectDetailsSectionTitle {
        margin-top: -1rem;
    }
}