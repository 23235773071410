#expertise {
    margin-top: 5rem;
    min-height: 90vh;
    overflow: hidden;
    scroll-margin-top: 6rem;
}



.expertise_section_title_container :is(h5, h2) {
    text-align: center;

}

.expertise_sectionH5.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.expertise_sectionH2.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.expertise_sectionH5.show {
    color: var(--color-light-button-outline);
    font-size: 1.25rem;
    opacity: 1;
    visibility: visible;
    transition: all 1s;
    animation-name: about_title_slide;
    animation-duration: 2s;
    filter: blur(0);
}

.expertise_sectionH2.show {
    opacity: 1;
    color: var(--color-bg-bright);
    font-size: 3rem;
    visibility: visible;
    transition: all 1s;
    animation-name: about_h2_slide;
    animation-duration: 2s;
    filter: blur(0);
}

.skills_outer_container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 80vh;

}

.skills_container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5rem;
}

.front_end_container.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.back_end_container.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.front_end_container.show,
.back_end_container.show {
    background-color: var(--color-bg-bright);
    border-radius: 12px;
    color: var(--color-primary-font);
    height: fit-content;
    padding: .50rem .50rem;
    transition: var(--transition);
    width: fit-content;
    position: relative;

    opacity: 1;
    visibility: visible;
    filter: blur(0);

    animation-name: animated-disc-title;
    animation-duration: 1.75s;
    transition: var(--transition);
    transition-delay: 2s;


}



.front_end_container:hover,
.back_end_container:hover,
.icons:hover {
    background-color: transparent;
    border: 2px solid var(--color-bg-bright);
}



.experienced_frontend {
    align-items: center;
    display: flex;
    font-size: 1.55rem;
    gap: .5rem;
    justify-content: center;
    margin-top: .5rem;
}

.laptop,
.server {
    height: 1rem;
    margin-bottom: .25rem;
    width: 1.2rem;
}

.inner_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem 1rem;

}


.inner_container:hover {
    background-color: transparent;
}



.icons {
    align-items: center;
    border: 1px solid var(--color-primary-font);
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    height: 6rem;
    gap: .5rem;
    justify-content: center;
    padding: .5rem .5rem;
    transition: var(--transition);
    width: 6rem;
}

.icon {
    width: 2rem;
    height: 2rem;
}

article>h4 {
    font-size: .8rem;

}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    .skills_container {
        flex-wrap: wrap;
        gap: 2rem;
    }

    .skills_outer_container {
        min-height: 65vh;
    }


}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {


    .skills_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .skills_outer_container {
        margin-top: 3rem;
    }

    .front_end_container,
    .back_end_container {
        width: 100%;
    }


    .icons {
        height: 5.25rem;
        width: 5.25rem;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    article>h4 {
        font-size: .65rem;

    }

}