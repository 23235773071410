#about_section {
    margin-top: 5rem;
    min-height: 90vh;
    overflow: hidden;
    scroll-margin-top: 6rem;

}

/* .about_section h5,
.about_section h2 {
    position: relative;
} */

.about_section_title_container :is(h5, h2) {
    text-align: center;
}

.about_sectionH5.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.about_sectionH2.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.about_sectionH5.show {
    color: var(--color-light-button-outline);
    font-size: 1.25rem;
    opacity: 1;
    visibility: visible;
    transition: all 1s;
    animation-name: about_title_slide;
    animation-duration: 2s;
    filter: blur(0);
}

.about_sectionH2.show {
    opacity: 1;
    color: var(--color-bg-bright);
    font-size: 3rem;
    visibility: visible;
    transition: all 1s;
    animation-name: about_h2_slide;
    animation-duration: 2s;
    filter: blur(0);
}



.outer_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    min-height: 80vh;

}

.about_container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    position: relative;

}

.story.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

    transition: var(--transition);
}

.story.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-name: animated-disc-title;
    animation-duration: 2s;
    transition: var(--transition);
    transition-delay: 1s;
}

.about_me_inner.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

    transition: var(--transition);
}

.about_me_inner.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-name: animated-disc-title;
    animation-duration: 2s;
    transition: var(--transition);
    transition-delay: 1s;
}




.education_container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    position: relative;
    margin-top: 1.5rem;
}



blockquote {
    border: 1px solid var(--color-bg-bright);
    border-bottom: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 1.5rem;
    padding-bottom: 0;

}



blockquote p {
    color: var(--color-primary-font);
    font-size: 1rem;
    line-height: 1.5;
    padding: .5rem .5rem;
    text-align: justify;
}

.about_me_inner_list {
    color: var(--color-primary-font);
    line-height: 1.2;
    text-align: justify;
}

.about_me_inner_list li {
    font-size: 1rem;


}

.about_me_inner_list li span {
    font-style: italic;
    font-weight: 200;
    margin-left: .25rem;
}


blockquote:before,
blockquote:after {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    width: 6.5rem;
    height: 1.25rem;
    padding: 1rem 1rem;
}

blockquote::before {
    background-color: var(--color-bg-primary);
    color: var(--color-bg-bright);
    content: "< My Story />";
    margin-top: -3.55rem;

}


button.button_light {
    margin-bottom: 1rem;
    font-size: 1rem;
}


#education::before {
    background-color: var(--color-bg-primary);
    color: var(--color-bg-bright);
    content: "< Formal Education />";
    margin-top: -3.55rem;
}



#education:before,
#education:after {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    width: 11.5rem;
    height: 1.25rem;
    padding: 1rem 1rem;
}

.education.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.education.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-name: about_title_slide;
    animation-duration: 2s;
    transition: var(--transition);
    transition-delay: 1s;
}

#other_edu::before {
    background-color: var(--color-bg-primary);
    color: var(--color-bg-bright);
    content: "< Other Education />";
    margin-top: -3.55rem;
}


#other_edu:before,
#other_edu:after {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    width: 11rem;
    height: 1.25rem;
    padding: 1rem 1rem;
}

.other_edu.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.other_edu.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-name: about_h2_slide;
    animation-duration: 2s;
    transition: var(--transition);
    transition-delay: 1s;
}

.ul_title {
    font-size: 1rem;
    font-weight: bold;
}

.ul_list {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
    line-height: .5;
    margin-left: 1rem;
}


@keyframes about_title_slide {
    0% {
        transform: translateX(-200%)
    }

    100% {
        transform: translateX(0%)
    }


}


@keyframes about_h2_slide {
    0% {
        transform: translateX(200%)
    }

    100% {
        transform: translateX(0%)
    }
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    #about_section {
        min-height: 40vh;
    }

    .outer_container {
        min-height: 50vh;
    }

    blockquote p {
        font-size: .85rem;
        line-height: 1.25;
        padding: .25rem .25rem;
    }

    .about_me_inner_list li {
        font-size: .85rem;
        line-height: 1.5;
        text-align: left;
    }

    .about_me_ul li.ul_list {

        margin-top: -1rem;

    }

    .ul_list {
        line-height: 1;
        margin-left: .5rem;

    }


}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .outer_container {
        margin-top: 5rem;
        ;
    }

    .education_container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

    }

    .ul_list {
        line-height: 1.5;
    }

    blockquote button.button_light {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }

}