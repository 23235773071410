#hero {
    overflow: hidden;
}

.hero_section.hide_background {
    margin: 0;
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}


.hero_section.show_background {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    margin: 0;
    animation-duration: 3.25s;
    animation-name: animated-disc-title;
    transition-delay: 700ms
}

.hero_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}


.innerHeroContainer.hide_container {
    opacity: 0;
    filter: blur(5px);
    transition: var(--transition);

}

.innerHeroContainer.show_container {
    align-items: center;
    animation-duration: 2.75s;
    animation-name: animated-disc-title;
    background-color: rgba(0, 0, 0, .85);
    border-radius: 5rem;
    color: var(--color-primary-font);
    display: flex;
    filter: blur(0);
    gap: 3rem;
    justify-content: center;
    padding: 1rem 4rem 1rem 4rem;
    opacity: 1;
}



.bubble {
    aspect-ratio: 1/1;
    background-color: var(--color-bg-bright);
    border-radius: 0% 50% 50% 0%;
    mask-image: gradient(linear, right 10%, left 10%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    -webkit-mask-image: -webkit-gradient(linear, right 10%, left 10%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    position: relative;
    width: 15rem;
}



.hello {
    font-size: 5vw;
    font-weight: 700;
    line-height: 1;
    position: relative;
    text-align: left;
}

span {
    display: inline-block;
}

.developer {
    color: transparent;
    font-size: 3vw;
    font-weight: 700;
    text-align: left;
    position: relative;
    -webkit-text-stroke: 1px var(--color-bg-bright);
}

.introduction {
    display: flex;
    flex-direction: column;

}

.introduction h4 {
    text-align: left;
    position: relative;
    width: 36vw;
}

.starScene {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    animation: animateStars linear infinite;
    z-index: -30;
}


@media(perfers-reduced-motion) {
    .hidden {
        transition: none;
    }
}

@keyframes animated-disc-title {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

@keyframes animateStars {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    10%,
    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}

@keyframes animate-disc-sub-text {
    0% {
        transform: translateX(-200%)
    }

    100% {
        transform: translateX(0%)
    }
}



/* =============== Header Socials =============== */
.hero_socials {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    gap: 0.5rem;
    left: 0;
    position: relative;
}

.hero_socials::after {
    background: var(--color-bg-bright);
    content: " ";
    height: 2rem;
    width: 1px;
}

.light_oval {
    width: 17rem;
}

#me {
    width: 17rem;
}


/* =============== Header Socials =============== */

/* ===================== CTA ===================== */
.cta {
    display: flex;
    gap: 1rem;
    position: relative;
}

/* ===================== CTA ===================== */




/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    .hero_container {
        min-height: 90vh;
    }



}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media (max-width: 600px) {

    .innerHeroContainer.show_container {
        flex-direction: column;
        height: 85vh;
        width: 99vw;
        border-radius: 2rem;
    }

    .bubble {
        aspect-ratio: 1/1;
        width: 10rem;
    }

    .introduction {
        align-items: center;

        flex-direction: column;
        order: 1;
        text-align: center;
        width: 100vw;

    }


    .developer {
        font-size: 9vw;
    }

    .hello {
        font-size: 12vw;
    }

    .hero_socials {
        flex-direction: row;
        gap: 2rem;
        top: 1rem;
    }

    .hero_socials {
        order: 3;
    }

    .hero_socials::after {
        display: none;
    }

    .cta {
        margin-top: .5rem;
    }

    .introduction h4 {
        min-width: 80%;
        text-align: center;
    }

    .light_oval {
        width: 13rem;
        height: 14rem;
    }

    #me {
        width: 14rem;
        height: 12rem;
        margin: .5rem 0rem 0 -2rem;
    }


}