.side_project_divider.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.side_project_divider.show {
    border-bottom: 2px solid var(--color-font-hover);

    animation-name: animated-disc-title;
    animation-duration: 2s;
    filter: blur(0);
    opacity: 1;
    visibility: visible;

}

.skill_builders.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.skill_builders.show {
    color: var(--color-font-hover);
    font-size: 1.5rem;
    margin-top: 4rem;

    animation-name: animated-disc-title;
    animation-duration: 2s;
    filter: blur(0);
    opacity: 1;
    visibility: visible;

}


.side_project_section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0 2rem 0;
    overflow: hidden;
    position: relative;
}



/* NEW STUFF */

.project_section_title_container :is(h5, h2) {
    text-align: center;

}

.project_sectionH5.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.project_sectionH2.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.project_sectionH5.show {
    color: var(--color-light-button-outline);
    font-size: 1.25rem;
    visibility: visible;
    transition: all 1s;


    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: section-move-up;
}

.project_sectionH2.show {

    color: var(--color-bg-bright);
    font-size: 3rem;
    visibility: visible;
    transition: all 1s;


    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: section-move-up;
}

.projects_container {
    min-height: 85vh;
    margin-top: 5rem;
    overflow-y: scroll;
}


.newWayContainer {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;


}



.newWaySection.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.newWaySection.show {
    width: 100%;
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;

    opacity: 1;
    visibility: visible;
    filter: blur(0);

    animation-name: animated-disc-title;
    animation-duration: 2s;
    filter: blur(0);
}


#projectCard:nth-child(even) .imgContainer {

    order: 2;
}

#projectCard:nth-child(even) .informationContainer {

    order: 1;

}


#projectCard2:nth-child(even) .imgContainer {

    order: 2;
}

#projectCard2:nth-child(even) .informationContainer {

    order: 1;
}


.informationContainer {
    align-items: flex-start;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.5rem;
    width: 45%;
}



.projectTitleContainer h3 {
    font-size: 2rem;
}

.projectTag h4 {
    font-size: 1.25rem;
    font-weight: 200;
}


.techStackContainer .project_icons {
    color: var(--color-primary-font);
    font-size: 1.75rem;
    margin-bottom: .75rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.overviewContainer {
    margin-bottom: 1.25rem;
}

.project_buttons_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: -1rem;
}


.imgContainer {
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    display: flex;
    height: 300px;
    position: relative;
    width: 34rem;


    justify-content: center;

    overflow: hidden;
}

.imgContainer::after {
    content: '';
    position: absolute;
    width: 900px;
    height: 900px;
    background-image: conic-gradient(transparent, transparent, transparent, var(--color-bg-bright));
    animation: animate 4s linear infinite;
    animation-delay: -2s;
}

.imgContainer::before {
    content: '';
    position: absolute;
    width: 900px;
    height: 900px;
    background-image: conic-gradient(transparent, transparent, transparent, #b8ace3);
    animation: animate 4s linear infinite;
}

.imgContainerTwo {
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    display: flex;
    height: 300px;
    position: relative;
    width: 34rem;

    justify-content: center;

    overflow: hidden;
}

.imgContainerTwo::after {
    content: '';
    position: absolute;
    width: 900px;
    height: 900px;
    background-image: conic-gradient(transparent, transparent, transparent, var(--color-primary-font));
    animation: animate 4s linear infinite;
    animation-delay: -2s;
}

.imgContainerTwo::before {
    animation: animate 4s linear infinite;
    background-image: conic-gradient(transparent, transparent, transparent, #0c4ad0a8);
    content: '';
    height: 900px;
    position: absolute;
    width: 900px;
}

.project_img_two {
    border-radius: 20px;
    height: 285px;
    width: 530px;
    z-index: 1;
}


.imgContainer .project_img_two {
    position: absolute;
    inset: 6px;
    background: #0c1022;
    border-radius: 16px;
    z-index: 1;
}

/* SIDE PROJECT SECTION */
.sideProjectnewWaySection.show {
    width: 100%;
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;

    opacity: 1;
    visibility: visible;
    filter: blur(0);

    animation-name: about_h2_slide;
    animation-duration: 2s;
    filter: blur(0);
}

.sideProjectnewWayContainer {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;
}



@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animated-mock-title {
    0% {
        transform: translateY(-200%);
    }

    100% {
        transform: translateY(0%);
    }


}

@keyframes section-move-up {
    0% {
        transform: translateY(200%);
    }

    100% {
        transform: translateY(0%);
    }


}




/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media(hover: none) {

    .project_img,
    .side_project_img {
        opacity: .3;
    }

    .project_img_box,
    .side_project_img_box {
        background: rgb(2, 0, 36);
        background-image: linear-gradient(to bottom, #000000, #11090d, #1a1017, #211621, #261c2d, #2f223f, #362a53, #3a3269, #473c8b, #5546ae, #644fd1, #7558f6);

    }


    .project_text,
    .side_project_text {
        opacity: 1;
        margin-top: -2rem;
    }



}

@media screen and (max-width: 1024px) {

    .projects_container {
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 4rem;
    }

    .projects_container {
        display: flex;
        flex-direction: column;

    }

    .newWayContainer,
    .sideProjectnewWayContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -1rem;
    }

    .informationContainer {
        margin: 1rem 0 1rem 0;
        width: 80%
    }

    #projectCard:nth-child(even) .imgContainer {
        order: 1;
    }

    #projectCard:nth-child(even) .informationContainer {
        order: 2;
    }



}



@media screen and (max-width: 1325px) {
    .projects_container {
        display: flex;
        flex-direction: column;
    }

    .project_img_two {

        height: 355px;
        width: 625px;

    }

    .imgContainer,
    .imgContainerTwo {
        height: 370px;
        width: 635px;
        gap: 1rem;
    }

    .imgContainer::after {
        height: 800px;
        width: 800px;
    }

    .imgContainer::before {
        height: 800px;
        width: 800px;
    }

    .newWayContainer,
    .sideProjectnewWayContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -1rem;
    }

    .informationContainer {
        margin: 1rem 0 1rem 0;
        width: 80%
    }

    #projectCard:nth-child(even) .imgContainer {
        order: 1;
    }

    #projectCard:nth-child(even) .informationContainer {
        order: 2;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    /* .outer_container {
        margin-top: 5rem;
       
    } */

    #projectCard {
        margin-bottom: -2rem;

    }

    .project_img_two {

        height: 187px;
        width: 323px;

    }

    .projects_container {
        gap: .25rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .newWayContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }



    .imgContainer {
        height: 200px;
        width: 335px;
        gap: 1rem;
    }

    .imgContainer::after {
        height: 600px;
        width: 600px;
    }

    .imgContainer::before {
        height: 600px;
        width: 600px;
    }


    .projectTitleContainer {
        text-align: center;
        width: 18rem;
    }

    .techStackContainer {
        margin-top: 1rem;
    }


    .informationContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #projectCard:nth-child(even) .imgContainer {
        order: 1;
    }

    #projectCard:nth-child(even) .informationContainer {
        order: 2;
    }

    .projectTag h4 {
        text-align: center;
    }

    .appSignInContainer {
        width: 85%;
    }


    .overviewContainer {
        margin-top: .5rem;
        width: 85%;
        text-align: justify;
    }


    /* ========== SIDE PROJECTS ========== */
    .side_project_section {
        gap: .25rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

    }

    .sideProjectnewWayContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .imgContainerTwo {
        height: 185px;
        width: 315px;
        gap: 1rem;
    }

    .imgContainerTwo::after {
        height: 600px;
        width: 600px;
    }

    .imgContainerTwo::before {
        height: 600px;
        width: 600px;

    }



}