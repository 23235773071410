.navbar {

    background-color: var(--color-bg-primary);
    color: var(--color-primary-font);
    display: flex;
    height: 6rem;
    justify-content: center;
    /* overflow: hidden; */
    position: sticky;
    top: 0;
    z-index: 999;
}


.logo_container.hide_logo {
    align-items: center;
    cursor: pointer;
    display: flex;
    filter: blur(15px);
    opacity: 0;
    transition: all 1s;
}


.logo_container.show_logo {
    animation-duration: 1.5s;
    animation-name: animated-disc-title;
    filter: blur(0);
    opacity: 1;
}

.navbar_container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: var(--container-width-lg);
    max-width: 1300px;
    z-index: 1;

}


.navbar_menu.hide_menu {
    filter: blur(15px);
    opacity: 0;
    transition: var(--transition);
    position: absolute;
    top: -100%;


}


.navbar_menu.show_menu {
    animation-duration: 2s;
    animation-name: animated-disc-title;
    align-items: center;
    display: flex;
    filter: blur(0);
    gap: 2rem;
    justify-content: flex-end;
    opacity: 1;

    -webkit-animation-name: animated-disc-title;
    -webkit-animation-duration: 2s;

}


#mobile_menu {
    cursor: pointer;
    display: none;
    position: relative;
}


.bar {
    background-color: var(--color-bg-bright);
    display: block;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    width: 2rem;
}



@keyframes animated-disc-title {
    0% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }


}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    .navbar_container {
        align-items: center;
        width: var(--container-width-md);
    }

    #mobile_menu {
        display: block;
        position: absolute;
        top: 20%;
        right: 5%;
        transform: translate(5%, 20%);

    }




    #mobile_menu.is_active .bar:nth-child(2) {
        opacity: 0;
    }

    #mobile_menu.is_active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    #mobile_menu.is_active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }


    .navbar_menu {
        background-color: var(--color-bg-primary);
        border: 1px solid var(--color-bg-bright);

        left: -100%;
        top: 70px;
        flex-direction: column;

        gap: 1rem;
        position: fixed;
        width: 100%;
        text-align: center;
        transition: var(--transition);
        z-index: 999;
    }

    .navbar_item {
        margin: 1rem 0;
    }

    .navbar_menu.active {
        left: 0rem;
    }



}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    #mobile_menu {

        display: block;
        position: absolute;
        top: 20%;
        right: 5%;
        transform: translate(5%, 20%);
        z-index: 999;

    }

    .navbar_item {
        margin: .5rem 0;
    }


    .navbar_menu li:nth-child(5) {
        margin-bottom: 1rem;

    }

}