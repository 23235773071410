#contact {
    margin-top: 5rem;
    min-height: 90vh;
    overflow: hidden;
    scroll-margin-top: 6rem;

}

.contact_section_title_container :is(h5, h2) {
    text-align: center;

}

.conctact_sectionH5.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.conctact_sectionH2.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);

}

.conctact_sectionH5.show {
    color: var(--color-light-button-outline);
    font-size: 1.25rem;
    visibility: visible;
    transition: all 1s;


    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: animated-disc-title;
}

.conctact_sectionH2.show {

    color: var(--color-bg-bright);
    font-size: 3rem;
    visibility: visible;
    transition: all 1s;


    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: animated-disc-title;
}



.contact_form {
    margin-top: 5rem;
    overflow: hidden;
}

.input_field {
    display: flex;
    justify-content: center;
    min-width: 70vw;

}


form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.15rem;
}

input,
textarea {
    background: transparent;
    border: 2px solid var(--color-bg-bright);
    color: white;
    border-radius: 12px;
    padding: 1.5rem;
    position: relative;
    resize: none;
    width: 50%;

}

input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: .75rem;
}

textarea::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: .75rem;
}

input {
    height: 2rem;
    position: relative;
}

.contact_form {
    position: relative;
}



.inputName.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.inputEmail.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.inputMessage.hidden {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
}

.inputName.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);

    animation-duration: 2s;
    animation-name: about_title_slide;
}

.inputEmail.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-duration: 2s;
    animation-name: about_h2_slide;
}

.inputMessage.show {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    animation-duration: 2s;
    animation-name: about_title_slide;
}


.email_sent {
    color: var(--color-primary-font);
    font-size: 1.25rem;
    text-align: center;
}

.input_contact_button {
    background-color: transparent;
    border: 1px solid var(--color-bg-bright);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    color: var(--color-bg-bright);
    font-size: 1.1rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    outline: none;
    padding: .35rem 1.25rem;
    transition: var(--transition);
}


.spinner {
    animation: spin 1s infinite linear;
    color: var(--color-bg-bright);
    height: 2rem;
    transition: var(--transition);
    width: 2rem;


}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    #contact {
        margin-top: 3rem;
        min-height: 50vh;
    }

    input,
    textarea {
        width: 75%;
    }


}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    #contact {
        margin-top: 3rem;
        min-height: 50vh;
    }

    .contact_container {
        margin-top: 2rem;
    }

    input,
    textarea {
        width: 90%;

    }

    textarea {
        height: 10rem;
    }

}