.logo {
    color: var(--color-bg-bright);
    display: flex;
    gap: .30rem;
    font-size: 2.5rem;
    font-weight: 600;
    position: relative;
    text-align: center;
}

.footer_logo {
    color: var(--color-font-black-grey);
    display: flex;
    gap: .30rem;
    font-size: 2.5rem;
    font-weight: 600;
    position: relative;
    text-align: center;
}